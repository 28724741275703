import {
    resolveBookingRule,
    resolveRepeatDates,
    resolveRule,
    RULE_TYPE_CUSTOM,
    RULE_TYPE_OPTIN,
    RuleResolveResult,
    RuleResolverOptions
} from './rule-resolver'
import moment from 'moment-timezone'
import {
    BOOKING_STATUS_BLOCKED,
    BOOKING_STATUS_BOOKED,
    CLEANING_STATUS_DIRTY,
    CLEANING_STATUS_INSPECTION,
    CLEANING_STATUS_NO_SERVICE,
    CLEANING_STATUS_OUT_OF_SERVICE,
    OCCUPANCY_CHECKIN,
    OCCUPANCY_VACANT
} from './txt-constants'
import { findLeadBooking } from './booking-service'
import { isFeatureOn } from './feature-toggles'
import { ActivityStruct, AreaCleaningStatus, AreaStruct, BookingStruct, Occupancy, OrgStruct, RuleStruct } from './firestore-structs'
import { Firebase, FirebaseFirestore } from './firebase'

type ExtRuleResolveResult = Partial<RuleResolveResult> &
    Partial<RuleStruct> & {
        name?: string
        description?: string
        priority?: number
        checklistTasks?: string[]
        customChar?: string
    }

type ExtAreaStruct = AreaStruct & { rules?: RuleStruct[]; ruleName?: string }

function sortTimeStampAscending(a: number | string, b: number | string) {
    // if (a == null || b == null) return
    let result = 0

    let aTimeStamp = a
    let bTimeStamp = b

    if (aTimeStamp === undefined) aTimeStamp = 0
    if (bTimeStamp === undefined) bTimeStamp = 0

    result = Number(aTimeStamp) - Number(bTimeStamp)
    return result
}

function getOccupancyFromBookings(bookings: BookingStruct[], d: number) {
    const checkinBookings = bookings.filter(b => b.checkinDate === d)
    const checkoutBookings = bookings.filter(b => b.checkoutDate === d)
    const stayoverBookings = bookings.filter(b => b.checkinDate !== d && b.checkoutDate !== d && b.bookingDates.includes(d.toString()))

    let occupancy = ''

    if (stayoverBookings.length > 0) {
        occupancy = 'stayover'
    } else if (checkinBookings.length > 0 && checkoutBookings.length > 0) {
        occupancy = 'turnover'
    } else if (checkinBookings.length > 0) {
        occupancy = 'checkin'
    } else if (checkoutBookings.length > 0) {
        occupancy = 'checkout'
    } else {
        occupancy = 'vacant'
    }

    return occupancy
}

export async function fetchBookingsForDate(firebase: Firebase | FirebaseFirestore, date: number, organizationKey: string) {
    const bookings: BookingStruct[] = []
    const db = 'firestore' in firebase ? firebase.firestore() : firebase

    const bookingSnap = await db
        .collection('bookings')
        .where('organizationKey', '==', organizationKey)
        .where('bookingDates', 'array-contains', date.toString())
        .get()

    bookingSnap.docs.forEach(doc => {
        const b = doc.data() as BookingStruct
        if (b.status && b.status.toLowerCase() !== 'cancelled') {
            bookings.push(b)
        }
    })

    return bookings
}

export function getActiveRule(
    area: Pick<ExtAreaStruct, 'rules' | 'inspection' | 'cleaningStatus'>,
    bookings: BookingStruct[],
    date: number,
    org: Pick<OrgStruct, 'timezone' | 'featuresEnabled' | 'featuresDisabled'>
) {
    const results: ExtRuleResolveResult[] = []
    const options = {
        cleanUntilCheckin: isFeatureOn(org, 'clean-until-checkin')
    }
    area.rules!.forEach(r => {
        const result: ExtRuleResolveResult = resolveRule(r, bookings, date, org, area, options)
        if (result && Object.keys(result).length > 0) {
            result.name = r.name
            result.description = r.description
            result.priority = r.priority
            results.push(result)
        }
        if (result.inspection) {
            area.inspection = result.inspection
        }
    })
    const sortedResults = results.sort((a, b) => b.priority! - a.priority!)
    const activeResult = sortedResults.length > 0 ? sortedResults[0] : {}

    return activeResult
}

function calculateRules<
    Area extends Pick<ExtAreaStruct, 'rules' | 'inspection' | 'occupancy' | 'ruleName' | 'activeRule' | 'cleaningStatus'>
>(area: Area, bookings: BookingStruct[], date: number, org: Pick<OrgStruct, 'timezone'>, options: { cleanUntilCheckin: boolean }) {
    const results = area.rules!.flatMap(r => {
        const result: ExtRuleResolveResult = resolveRule(r, bookings, date, org, area, options)
        if (result.inspection) {
            area.inspection = result.inspection
            const checkoutBookings = bookings.filter(b => b.checkoutDate === date)
            if (checkoutBookings && checkoutBookings.length > 0 && area.rules!.filter(r => r.repeatType === 'checkout').length > 0) {
                result.cleaningStatus = CLEANING_STATUS_INSPECTION
            }
        }
        if (result && Object.keys(result).length > 0) {
            result.name = r.name
            result.description = r.description
            result.priority = r.priority
            result.checklistTasks = r.checklistTasks
            result.customChar = r.customChar
            return [result]
        }
        return []
    })

    // console.debug(`Results for area: ${area.name} and date: ${date} are: ${JSON.stringify(results)}`)
    const sortedResults = results.sort((a, b) => b.priority! - a.priority!)
    const activeResult = sortedResults.length > 0 ? sortedResults[0] : {}

    const leadBooking = findLeadBooking(area, bookings, date)

    // console.debug(`Active result for area: ${area.name} and date: ${date} is: ${JSON.stringify(activeResult)}`)
    if (activeResult) {
        if (activeResult.occupancy) {
            area.occupancy = activeResult.occupancy
            area.ruleName = activeResult.name
            area.activeRule = activeResult as RuleStruct
        }
        if (activeResult.cleaningStatus) {
            area.cleaningStatus = activeResult.cleaningStatus
            area.ruleName = activeResult.name
            area.activeRule = activeResult as RuleStruct
        }
        if (activeResult.inspection) {
            if (area.cleaningStatus === 'inspection') {
                area.ruleName = activeResult.name
                area.activeRule = activeResult as RuleStruct
            }
        }
        if (activeResult.optInDates) {
            leadBooking!.optInDates = activeResult.optInDates.map(x => x.toString())
        }
    }

    return area
}

const wasLastExtraCleaningSkipped = (
    rules: RuleStruct[],
    booking: BookingStruct,
    date: number,
    org: Pick<OrgStruct, 'timezone'>,
    area: Pick<AreaStruct, 'occupancy' | 'cleaningStatus' | 'daysSinceLastCleaning' | 'daysSinceLastCheckout'>,
    options: RuleResolverOptions
) => {
    const extraCleaningRule = rules.find(x => x.repeatType === 'custom' && x.repeatInterval > 1)
    // No extra cleaning rule, so no extra cleaning was skipped
    if (!extraCleaningRule) {
        return false
    }
    // extra cleaning rule already applied so no extra cleaning was skipped
    if (resolveBookingRule(extraCleaningRule, [booking], date, org, area, options).occupancy === 'stayover-80') {
        return false
    }

    // we only only need to check dates pror to the date being checked against
    const extraCleamningDays = resolveRepeatDates(extraCleaningRule, booking.checkinDate, booking.checkoutDate, org)
    const allExtraCleaningDaysPrior = extraCleamningDays.filter(x => moment(x).isSameOrBefore(date))

    // if the day is extra cleaning we don't need to check if the last extra cleaning was skipped
    if (allExtraCleaningDaysPrior.includes(date)) {
        return false
    }
    // No extra cleaning prior so no extra cleaning was skipped
    if (allExtraCleaningDaysPrior.length === 0) {
        return false
    }
    const extraCleaningDaysOptedInto = booking
        .optInDates!.filter(x => allExtraCleaningDaysPrior.includes(parseInt(x)))
        .sort(sortTimeStampAscending)

    // Some skipped extra cleaning days
    if (allExtraCleaningDaysPrior.length !== extraCleaningDaysOptedInto.length) {
        // No extra cleaning days opted into so last extra cleaning was skipped
        if (extraCleaningDaysOptedInto.length === 0) {
            return true
        }
        const lastExtraCleaningDayOptedInto = extraCleaningDaysOptedInto[extraCleaningDaysOptedInto.length - 1]
        const daysSinceLastExtraCleaning = moment(date).diff(moment(parseInt(lastExtraCleaningDayOptedInto)), 'days')
        // if the last extra cleaning was skipped, return true
        if (lastExtraCleaningDayOptedInto && daysSinceLastExtraCleaning >= extraCleaningRule.repeatInterval) {
            return true
        }
    }

    return false
}

function calculateOptIns<
    Area extends Pick<ExtAreaStruct, 'rules' | 'inspection' | 'occupancy' | 'ruleName' | 'activeRule' | 'cleaningStatus'>
>(
    area: Area,
    bookings: BookingStruct[],
    date: number,
    rules: RuleStruct[],
    org: Pick<OrgStruct, 'timezone'>,
    options: { cleanUntilCheckin: boolean }
) {
    const leadBooking = findLeadBooking(area, bookings, date)

    if (leadBooking) {
        if (leadBooking.optInDates && leadBooking.optInDates.includes(date.toString())) {
            if (wasLastExtraCleaningSkipped(rules, leadBooking, date, org, area, options)) {
                area.cleaningStatus = CLEANING_STATUS_DIRTY
                area.occupancy = 'stayover-80'
            } else {
                area = calculateRules(area, bookings, date.valueOf(), org, options)
            }
        } else {
            const optInRule = rules.find(r => r.repeatType === RULE_TYPE_OPTIN)
            if (optInRule) {
                // If there is a rule with higher priority it should take precedence
                const otherHousekeepingRule = rules.find(r => r.repeatType === RULE_TYPE_CUSTOM && r.repeatInterval > 1)
                if (otherHousekeepingRule && otherHousekeepingRule.priority > optInRule.priority) {
                    const otherHousekeepingRuleResults = resolveBookingRule(otherHousekeepingRule, [leadBooking], date, org, area, options)
                    if (otherHousekeepingRuleResults && otherHousekeepingRuleResults.cleaningStatus) {
                        area = calculateRules(area, bookings, date.valueOf(), org, options)
                    } else {
                        area.cleaningStatus = CLEANING_STATUS_NO_SERVICE
                    }
                } else {
                    area.cleaningStatus = CLEANING_STATUS_NO_SERVICE
                }
            } else {
                area.cleaningStatus = CLEANING_STATUS_NO_SERVICE
            }
        }
    } else {
        area = calculateRules(area, bookings, date.valueOf(), org, options)
    }

    return area
}

function calculateOptOuts<
    Area extends Pick<ExtAreaStruct, 'rules' | 'inspection' | 'occupancy' | 'ruleName' | 'activeRule' | 'cleaningStatus'>
>(area: Area, bookings: BookingStruct[], date: number, org: Pick<OrgStruct, 'timezone'>, options: { cleanUntilCheckin: boolean }) {
    const leadBooking = findLeadBooking(area, bookings, date)
    if (leadBooking && leadBooking.optOutDates && leadBooking.optOutDates.length > 0) {
        if (leadBooking.optOutDates.includes(date.toString())) {
            area.cleaningStatus = CLEANING_STATUS_NO_SERVICE
        } else {
            area = calculateRules(area, bookings, date.valueOf(), org, options)
        }
    } else {
        area = calculateRules(area, bookings, date.valueOf(), org, options)
    }

    return area
}

function calculateActivities(area: Pick<AreaStruct, 'cleaningStatus' | 'occupancy'>, activities: ActivityStruct[]) {
    const cleaningStatusActivities = activities.filter(a => a.change.field && a.change.field === 'cleaningStatus')
    const occupancyActivities = activities.filter(a => a.change.field && a.change.field === 'occupancy')

    if (cleaningStatusActivities.length > 0) {
        const latestActivity = cleaningStatusActivities[0]
        area.cleaningStatus = latestActivity.change.after as AreaCleaningStatus
    }

    if (occupancyActivities.length > 0) {
        const latestActivity = occupancyActivities[0]
        area.occupancy = latestActivity.change.after as Occupancy
    }

    return area
}

function calculateActivitiesNew<Area extends Pick<ExtAreaStruct, 'cleaningStatus' | 'occupancy'>>(
    area: Area,
    activities: ActivityStruct[]
) {
    const guestCheckedInOrOut = activities.filter(
        a => a.change.field && ['guestCheckedOut', 'guestCheckedIn'].includes(a.change.field) && a.change.after === true
    )
    const cleaningStatusActivities = activities.filter(a => a.change.field && a.change.field === 'cleaningStatus')
    const occupancyActivities = activities.filter(a => a.change.field && a.change.field === 'occupancy')

    const latestGuestCheckedOutActivity =
        guestCheckedInOrOut.length > 0 && guestCheckedInOrOut[0].change.field === 'guestCheckedOut' ? guestCheckedInOrOut[0] : null
    const latestCleaningStatusActivity = cleaningStatusActivities.length > 0 ? cleaningStatusActivities[0] : null
    if (!latestCleaningStatusActivity && latestGuestCheckedOutActivity) {
        area.cleaningStatus = CLEANING_STATUS_DIRTY
    } else if (latestCleaningStatusActivity) {
        area.cleaningStatus = latestCleaningStatusActivity.change.after as AreaCleaningStatus
    }

    if (occupancyActivities.length > 0) {
        const latestActivity = occupancyActivities[0]
        area.occupancy = latestActivity.change.after as Occupancy
    }

    return area
}

function calculateGuestStatus(area: Pick<AreaStruct, 'occupancy'>, bookings: BookingStruct[], d: number) {
    const checkinBookings = bookings.filter(b => b.checkinDate === d)
    const checkoutBookings = bookings.filter(b => b.checkoutDate === d)

    let guestCheckedIn = false
    let guestCheckedOut = false

    switch (area.occupancy) {
        case 'checkout':
            if (checkoutBookings[0] && checkoutBookings.length > 0 && checkoutBookings[0].guestCheckedOut) {
                guestCheckedOut = true
            }
            break
        case 'turnover':
            if (checkoutBookings[0] && checkoutBookings.length > 0 && checkoutBookings[0].guestCheckedOut) {
                guestCheckedOut = true
            }
            if (checkinBookings[0] && checkinBookings.length > 0 && checkinBookings[0].guestCheckedIn) {
                guestCheckedIn = true
            }
            break
        case 'checkin':
            if (checkinBookings[0] && checkinBookings.length > 0 && checkinBookings[0].guestCheckedIn) {
                guestCheckedIn = true
            }
            break
        case 'stayover':
            guestCheckedIn = true
            break
        case 'stayover-80':
            guestCheckedIn = true
            break
        default:
            break
    }

    return {
        guestCheckedIn,
        guestCheckedOut
    }
}

export async function calculateCleaningStatus(
    firebase: Firebase | FirebaseFirestore,
    areas: Pick<
        ExtAreaStruct,
        'rules' | 'inspection' | 'occupancy' | 'ruleName' | 'activeRule' | 'cleaningStatus' | 'key' | 'guestCheckedOut' | 'guestCheckedIn'
    >[],
    selectedDate: number,
    org: Pick<OrgStruct, 'key' | 'timezone' | 'featuresEnabled' | 'featuresDisabled' | 'allowOptIn' | 'allowOptOut'>,
    bookings: BookingStruct[] | null = null,
    activities: ActivityStruct[] | null = null,
    rules: RuleStruct[] = [],
    usingCleaningSchedule = false
) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    const date = moment.tz(selectedDate, org.timezone).startOf('day')
    const options = {
        cleanUntilCheckin: isFeatureOn(org, 'clean-until-checkin')
    }

    if (!bookings) {
        bookings = await fetchBookingsForDate(db, date.valueOf(), org.key)
    }

    if (activities === null) {
        // refactor to area-data
        const activitiesRef = await db
            .collectionGroup('activities')
            .where('organizationKey', '==', org.key)
            .where('date', '==', date.valueOf())
            .get()

        activities = activitiesRef.docs.map(activitiesSnap => {
            return activitiesSnap.data()
        })
    }

    const calcAreas = areas.map(area => {
        // console.debug(`Calculating cleaning status for area: ${area.name}, key: ${area.key}`)
        if (!date.isSame(moment.tz(org.timezone).startOf('day')) || usingCleaningSchedule) {
            area.cleaningStatus = 'clean'
            // console.debug(`Calculation is not for today, so setting cleaning status to clean`)
        }

        let areaBookings = bookings!.filter(b => {
            return b.areaKey === area.key
        })

        // Enrich the bookings with opt ins from extras
        // First check if there is an optin rule present
        const optInRule = rules.find(r => r.repeatType === RULE_TYPE_OPTIN)
        if (optInRule) {
            areaBookings.map(b => {
                const result = resolveBookingRule(optInRule, [b], date.valueOf(), org, area, options)
                if (result.optInDates) {
                    b.optInDates = result.optInDates.map(x => x.toString())
                }
            })
        }

        const areaActivities = activities!.filter(a => {
            return a.areaKey === area.key
        })

        const areaRules = rules.filter(r => {
            return r.areas.includes(area.key)
        })

        const rulesSorted = areaRules.sort((a, b) => a.priority - b.priority)

        area.rules! = rulesSorted
        areaBookings = areaBookings
            .filter(b => [BOOKING_STATUS_BOOKED, BOOKING_STATUS_BLOCKED].includes(b.status))
            .sort((a, b) => sortTimeStampAscending(a.checkinDate, b.checkinDate))
        const occupancyFromBookings = getOccupancyFromBookings(areaBookings, date.valueOf())
        // console.debug(`Occupancy from bookings: ${occupancyFromBookings}`)
        area.occupancy = occupancyFromBookings as Occupancy

        const newOOOBehaviour = isFeatureOn(org, 'new-ooo-behaviour')
        if (areaBookings.length > 0 && areaBookings[0].status && areaBookings[0].status === BOOKING_STATUS_BLOCKED) {
            if (newOOOBehaviour && date.valueOf() === areaBookings[0].checkoutDate) {
                area.cleaningStatus = CLEANING_STATUS_INSPECTION
            } else {
                area.cleaningStatus = CLEANING_STATUS_OUT_OF_SERVICE
            }
            area.occupancy =
                areaBookings[1] &&
                areaBookings[1].status === BOOKING_STATUS_BOOKED &&
                areaBookings[1].checkinDate === areaBookings[0].checkoutDate &&
                date.valueOf() === areaBookings[0].checkoutDate
                    ? OCCUPANCY_CHECKIN // setting OCCUPANCY_CHECKIN if there is the last day of blocked booking and today is the new guests arriving
                    : OCCUPANCY_VACANT
            // console.debug(`Area is blocked, so setting cleaning status to ${area.cleaningStatus} and occupancy to ${area.occupancy}`)
        }

        if (area.rules && area.rules.length > 0) {
            if (area.occupancy === 'stayover' && org && org.allowOptIn) {
                // area.cleaningStatus = CLEANING_STATUS_CLEAN
                const optIns = calculateOptIns(area, areaBookings, date.valueOf(), area.rules, org, options)
                // console.debug(
                //     `Area is stayover and org allows opt in, so calculating optin cleaning status: ${optIns.cleaningStatus} and occupancy: ${optIns.occupancy}`
                // )
                area = optIns
            } else if (area.occupancy === 'stayover' && org && org.allowOptOut) {
                // area = calculateRules(area, areaBookings, date.valueOf())
                const optOuts = calculateOptOuts(area, areaBookings, date.valueOf(), org, options)
                // console.debug(
                //     `Area is stayover and org allows opt out, so calculating optout cleaning status: ${optOuts.cleaningStatus} and occupancy: ${optOuts.occupancy}`
                // )
                area = optOuts
            } else {
                if (areaBookings[0]?.status !== BOOKING_STATUS_BLOCKED) {
                    const rules = calculateRules(area, areaBookings, date.valueOf(), org, options)
                    // console.debug(
                    //     `Area is not blocked, so calculating cleaning status from rules: cleaning status: ${rules.cleaningStatus} and occupancy: ${rules.occupancy}`
                    // )
                    area = rules
                }
            }
        }

        if (areaActivities.length > 0) {
            areaActivities.sort((a, b) => b.created - a.created)

            const activities = calculateActivitiesNew(area, areaActivities)
            // console.debug(
            //     `Area has activities, so calculating cleaning status from activities: ${activities.cleaningStatus} and occupancy: ${activities.occupancy}`
            // )
            area = activities
        }

        const guestStatus = calculateGuestStatus(area, areaBookings, date.valueOf())
        // console.debug(
        //     `Calculating guest status for area: ${area.name} with guest checked in: ${guestStatus.guestCheckedIn} and guest checked out: ${guestStatus.guestCheckedOut}`
        // )
        area.guestCheckedIn = guestStatus.guestCheckedIn
        area.guestCheckedOut = guestStatus.guestCheckedOut
        return area
    })

    return calcAreas
}

export function calculateCleaningStatusSync<
    Area extends Pick<
        ExtAreaStruct,
        'rules' | 'inspection' | 'occupancy' | 'ruleName' | 'activeRule' | 'cleaningStatus' | 'key' | 'guestCheckedOut' | 'guestCheckedIn'
    >
>(
    areas: Area[],
    selectedDate: number,
    org: Pick<OrgStruct, 'key' | 'timezone' | 'featuresEnabled' | 'featuresDisabled' | 'allowOptIn' | 'allowOptOut'>,
    bookings: BookingStruct[] = [],
    activities: ActivityStruct[] = [],
    rules: RuleStruct[] = [],
    usingCleaningSchedule?: boolean
) {
    const date = moment.tz(selectedDate, org.timezone).startOf('day')
    const options = { cleanUntilCheckin: isFeatureOn(org, 'clean-until-checkin') }

    const calcAreas = areas.map(area => {
        // console.debug(`Calculating cleaning status for area: ${area.name}, key: ${area.key}`)
        if (!date.isSame(moment.tz(org.timezone).startOf('day')) || usingCleaningSchedule) {
            area.cleaningStatus = 'clean'
            // console.debug(`Calculation is not for today, so setting cleaning status to clean`)
        }

        let areaBookings = bookings.filter(b => {
            return b.areaKey === area.key
        })

        // Enrich the bookings with opt ins from extras
        // First check if there is an optin rule present
        const optInRule = rules.find(r => r.repeatType === RULE_TYPE_OPTIN)
        if (optInRule) {
            areaBookings.map(b => {
                const result = resolveBookingRule(optInRule, [b], date.valueOf(), org, area, options)
                if (result.optInDates) {
                    b.optInDates = result.optInDates.map(x => x.toString())
                }
            })
        }

        const areaActivities = activities.filter(a => {
            return a.areaKey === area.key
        })

        const areaRules = rules.filter(r => {
            return r.areas.includes(area.key)
        })

        const rulesSorted = areaRules.sort((a, b) => a.priority - b.priority)

        area.rules = rulesSorted
        areaBookings = areaBookings
            .filter(b => [BOOKING_STATUS_BOOKED, BOOKING_STATUS_BLOCKED].includes(b.status))
            .sort((a, b) => sortTimeStampAscending(a.checkinDate, b.checkinDate))
        const occupancyFromBookings = getOccupancyFromBookings(areaBookings, date.valueOf())
        // console.debug(`Occupancy from bookings: ${occupancyFromBookings}`)
        area.occupancy = occupancyFromBookings as Occupancy

        const newOOOBehaviour = isFeatureOn(org, 'new-ooo-behaviour')
        if (areaBookings.length > 0 && areaBookings[0].status && areaBookings[0].status === BOOKING_STATUS_BLOCKED) {
            if (newOOOBehaviour && date.valueOf() === areaBookings[0].checkoutDate) {
                area.cleaningStatus = CLEANING_STATUS_INSPECTION
            } else {
                area.cleaningStatus = CLEANING_STATUS_OUT_OF_SERVICE
            }
            area.occupancy =
                areaBookings[1] &&
                areaBookings[1].status === BOOKING_STATUS_BOOKED &&
                areaBookings[1].checkinDate === areaBookings[0].checkoutDate &&
                date.valueOf() === areaBookings[0].checkoutDate
                    ? OCCUPANCY_CHECKIN // setting OCCUPANCY_CHECKIN if there is the last day of blocked booking and today is the new guests arriving
                    : OCCUPANCY_VACANT
            // console.debug(`Area is blocked, so setting cleaning status to ${area.cleaningStatus} and occupancy to ${area.occupancy}`)
        }

        if (area.rules && area.rules.length > 0) {
            if (area.occupancy === 'stayover' && org && org.allowOptIn) {
                // area.cleaningStatus = CLEANING_STATUS_CLEAN
                const optIns = calculateOptIns(area, areaBookings, date.valueOf(), area.rules, org, options)
                // console.debug(
                //     `Area is stayover and org allows opt in, so calculating optin cleaning status: ${optIns.cleaningStatus} and occupancy: ${optIns.occupancy}`
                // )
                area = optIns
            } else if (area.occupancy === 'stayover' && org && org.allowOptOut) {
                // area = calculateRules(area, areaBookings, date.valueOf())
                const optOuts = calculateOptOuts(area, areaBookings, date.valueOf(), org, options)
                // console.debug(
                //     `Area is stayover and org allows opt out, so calculating optout cleaning status: ${optOuts.cleaningStatus} and occupancy: ${optOuts.occupancy}`
                // )
                area = optOuts
            } else {
                if (areaBookings[0]?.status !== BOOKING_STATUS_BLOCKED) {
                    const rules = calculateRules(area, areaBookings, date.valueOf(), org, options)
                    // console.debug(
                    //     `Area is not blocked, so calculating cleaning status from rules: cleaning status: ${rules.cleaningStatus} and occupancy: ${rules.occupancy}`
                    // )
                    area = rules
                }
            }
        }

        if (areaActivities.length > 0) {
            areaActivities.sort((a, b) => b.created - a.created)

            const activities = calculateActivitiesNew(area, areaActivities)
            // console.debug(
            //     `Area has activities, so calculating cleaning status from activities: ${activities.cleaningStatus} and occupancy: ${activities.occupancy}`
            // )
            area = activities
        }

        const guestStatus = calculateGuestStatus(area, areaBookings, date.valueOf())
        // console.debug(
        //     `Calculating guest status for area: ${area.name} with guest checked in: ${guestStatus.guestCheckedIn} and guest checked out: ${guestStatus.guestCheckedOut}`
        // )
        area.guestCheckedIn = guestStatus.guestCheckedIn
        area.guestCheckedOut = guestStatus.guestCheckedOut

        return area
    })

    return calcAreas
}
