import { selector } from 'recoil'
import { dateFilterType, reportTableCleaningSummaryHeaders, reportTableDateHeaders, reportTablePeriodHeaders, tasksData } from './atoms.js'
import { formatHoursMinutesHuman, formatMonthDayYear, formatTimeHoursAndMinutes } from '../../../../utils/formatters'
import { sortByName, sortTimeStampAscending } from '@shared/helpers'
import { clone } from 'ramda'

const groupBy = (items, key) =>
    items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item]
        }),
        {}
    )

const reportSummaryByCleaningType = selector({
    key: 'reportSummaryByCleaningType',
    get: ({ get }) => {
        const tasksRaw = get(taskDataGenerator)

        const taskTypes = createDataMap(tasksRaw, 'taskName')

        return Object.values(taskTypes).map(type => {
            const time =
                type.tasks.reduce(function (a, b) {
                    return a + b.totalTimeExcludingPausesInMilliseconds
                }, 0) / type.tasks.length

            return { type: type.name, areasCleaned: type.tasks.length, averageTime: formatHoursMinutesHuman(time) }
        })
    }
})

const reportByUser = selector({
    key: 'reportByUser',
    get: ({ get }) => {}
})

function aggregateActionTimes(events, taskKey) {
    const actionTimes = {}
    let lastTimestamp = events[0].timestamp
    let lastAction = events[0].action

    for (let i = 1; i < events.length; i++) {
        const event = events[i]
        const timeSpent = event.timestamp - lastTimestamp

        if (actionTimes[lastAction] !== undefined) {
            actionTimes[lastAction] += timeSpent
        } else {
            actionTimes[lastAction] = timeSpent
        }

        lastAction = event.action
        lastTimestamp = event.timestamp
    }

    if (taskKey) {
        actionTimes['taskKey'] = taskKey
    }

    return actionTimes
}

function createUserMap(tasks, type = 'user') {
    const userMap = {}

    // Iterate over each task
    tasks.forEach(task =>
        (task.assignedTo || []).forEach(user => {
            // Use the user's key as the identifier in the object
            if (!userMap[user.key]) {
                const userTasks = tasks.filter(x => x.assignedTo && x.assignedTo.find(y => y.key === user.key))
                userMap[user.key] = {
                    key: user.key,
                    initials: user.initials,
                    name: user.name,
                    tasks: userTasks,
                    totalTasks: userTasks.length,
                    totalIdleTime: 0,
                    totalTime: 0,
                    totalActiveTime: 0,
                    cumulatedPausedTime: 0
                }
            }
        })
    )

    return userMap
}

const derivedTaskObject = (task, tasks, objKey) => {
    const filteredTasks = tasks.filter(x => x[objKey] === task[objKey])
    return {
        key: task[objKey],
        initials: task[objKey],
        name: task[objKey],
        tasks: filteredTasks,
        totalTasks: filteredTasks.length,
        totalIdleTime: 0,
        totalTime: 0,
        totalActiveTime: 0,
        cumulatedPausedTime: 0
    }
}

function createDataMap(tasks, objKey = 'taskName') {
    const dataMap = {}

    // Iterate over each task
    tasks.forEach(task => {
        // Use the user's key as the identifier in the object
        if (!dataMap[task[objKey]]) {
            dataMap[task[objKey]] = derivedTaskObject(task, tasks, objKey)
        }
    })

    return dataMap
}

function mergeOverlappingTasks(tasks) {
    // Sort tasks by start time (Unix timestamps)
    tasks.sort((a, b) => a.startTime - b.startTime)

    let mergedTasks = []
    let currentTask = clone(tasks[0])

    for (let i = 1; i < tasks.length; i++) {
        let nextTask = clone(tasks[i])

        // Check if the tasks overlap (next task starts before the current task ends)
        if (nextTask.startTime <= currentTask.endTime) {
            // Merge the tasks by extending the end time to the later end time
            currentTask.endTime = Math.max(currentTask.endTime, nextTask.endTime)
            currentTask.totalPauseTimeInMilliseconds = currentTask.totalPauseTimeInMilliseconds + nextTask.totalPauseTimeInMilliseconds
            currentTask.totalTimeExcludingPausesInMilliseconds += nextTask.totalTimeExcludingPausesInMilliseconds
        } else {
            // No overlap, so push the current task and move to the next one
            mergedTasks.push(currentTask)
            currentTask = nextTask
        }
    }

    // Push the last task
    mergedTasks.push(currentTask)

    return mergedTasks
}

const taskDataGenerator = selector({
    key: 'taskDataGenerator',
    get: ({ get }) => {
        const taskData = get(tasksData)

        return taskData.map(task => {
            if (!task.cleaning.end || !task.cleaning.start) {
                return null
            }

            const { start, end, pause, play, stop } = task.cleaning

            let timeline = [
                ...play.map(x => ({ timestamp: x, action: 'play' })),
                ...stop.map(x => ({ timestamp: x, action: 'stop' })),
                ...pause.map(x => ({ timestamp: x, action: 'pause' }))
            ]
            timeline = timeline.sort((a, b) => sortTimeStampAscending(a.timestamp, b.timestamp))

            let actualEndTime = end

            if (stop.length > 1) {
                actualEndTime = stop[stop.length - 2]
            }

            // Calculate total time (including pauses)
            const totalTime = actualEndTime - start
            const totalPauseTime = aggregateActionTimes(timeline)['pause'] || 0

            const activeTime = totalTime - totalPauseTime

            const taskDetails = {
                area: task.area,
                areaName: task.area.name,
                assignedTo: task.assignedTo,
                taskKey: task.key,
                taskName: task.name,
                startTime: start,
                endTime: actualEndTime,
                totalTimeInMilliseconds: totalTime,
                totalTimeExcludingPausesInMilliseconds: activeTime,
                totalPauseTimeInMilliseconds: totalPauseTime
            }

            return taskDetails
        })
    }
})

export const reportTableData = selector({
    key: 'reportTableData',
    get: ({ get }) => {
        const tasksRaw = get(taskDataGenerator)

        const dateFilter = get(dateFilterType)
        const summaryByCleaningType = get(reportSummaryByCleaningType)
        const userTaskMap = createUserMap(tasksRaw)

        Object.values(userTaskMap).forEach(userObj => {
            const { totalTime } = userObj

            // Sort the user's tasks by start time
            const mergedTasks = dateFilter === 'date' ? mergeOverlappingTasks(userObj.tasks) : userObj.tasks

            mergedTasks.sort((a, b) => a.startTime - b.startTime)

            // Calculate idle time as the gap between the end of one task and the start of the next
            for (let i = 0; i < mergedTasks.length - 1; i++) {
                const currentTaskEnd = mergedTasks[i].endTime
                const nextTaskStart = mergedTasks[i + 1].startTime
                const idleTime = nextTaskStart - currentTaskEnd
                if (idleTime > 0) {
                    userObj.totalIdleTime += idleTime
                }
            }

            for (const t of userObj.tasks) {
                // Update aggregated data

                const { startTime, endTime } = t

                // Update start of first task and end of last task
                const currentStart = startTime
                const currentEnd = endTime
                if (!userObj.startOfFirstTask || currentStart < userObj.startOfFirstTask) {
                    userObj.startOfFirstTask = currentStart
                }
                if (!userObj.endOfLastTask || currentEnd > userObj.endOfLastTask) {
                    userObj.endOfLastTask = currentEnd
                }
                userObj.cumulatedPausedTime += t.totalPauseTimeInMilliseconds
            }

            userObj.totalTime = userObj.endOfLastTask - userObj.startOfFirstTask
            userObj.totalActiveTime = userObj.totalTime - userObj.cumulatedPausedTime - userObj.totalIdleTime
            userObj.averageTaskTime = userObj.totalActiveTime / userObj.totalTasks
        })

        const userRowsRaw = Object.values(userTaskMap)

        let userRowsFormatted = userRowsRaw.map(user => {
            const {
                name,
                key,
                initials,
                totalTasks,
                averageTaskTime,
                startOfFirstTask,
                endOfLastTask,
                cumulatedPausedTime,
                totalActiveTime,
                totalIdleTime,
                totalTime,
                tasks
            } = user

            const areasCleaned = totalTasks
            const averageTime = formatHoursMinutesHuman(averageTaskTime)
            const start = formatTimeHoursAndMinutes(startOfFirstTask)
            const end = formatTimeHoursAndMinutes(endOfLastTask)
            const paused = formatHoursMinutesHuman(cumulatedPausedTime)
            const activeTime = formatHoursMinutesHuman(totalActiveTime)
            const idleTime = formatHoursMinutesHuman(totalIdleTime)
            const _totalTime = formatHoursMinutesHuman(totalTime)

            const reportTasks = tasks
                .sort((a, b) => sortTimeStampAscending(a.startTime, b.startTime))
                .map(taskItem => {
                    const {
                        area,
                        taskName,
                        startTime,
                        endTime,
                        totalTimeInMilliseconds,
                        totalTimeExcludingPausesInMilliseconds,
                        totalPauseTimeInMilliseconds
                    } = taskItem

                    const date = formatMonthDayYear(startTime)
                    const duration = formatHoursMinutesHuman(totalTimeExcludingPausesInMilliseconds)
                    const _start = formatTimeHoursAndMinutes(startTime)
                    const _end = formatTimeHoursAndMinutes(endTime)
                    const paused = formatHoursMinutesHuman(totalPauseTimeInMilliseconds)

                    return { area, task: taskName, date, duration, start: _start, end: _end, paused }
                })

            const coreTableData = {
                user: {
                    name: name,
                    key: key,
                    initials: initials
                },
                averageTime,
                areasCleaned,
                totalTime: _totalTime,
                reportTasks,
                activeTime
            }

            const dateTableData = {
                start,
                end,
                paused,
                idleTime
            }

            return dateFilter === 'date' ? { ...dateTableData, ...coreTableData } : coreTableData
        })

        const dateFilterCondition = dateFilter === 'date'

        userRowsFormatted = userRowsFormatted.sort((a, b) => sortByName(a.user.name, b.user.name))
        if (!dateFilterCondition) {
            return { userRows: userRowsFormatted, summaryByCleaningType }
        }
        return { userRows: userRowsFormatted, summaryByCleaningType }
    }
})

export const exportReportData = selector({
    key: 'exportReportData',
    get: ({ get }) => {
        const tableData = get(reportTableData)
        const dateFilter = get(dateFilterType)
        const dateHeaders = get(reportTableDateHeaders)
        const cleaningSummaryHeaders = get(reportTableCleaningSummaryHeaders)
        const periodHeaders = get(reportTablePeriodHeaders)

        let { userRows, summaryByCleaningType } = tableData

        userRows = userRows.map(r => {
            return {
                name: r.user.name,
                ...r
            }
        })

        const headers = dateFilter === 'date' ? dateHeaders : periodHeaders
        const convertToArrOfArrays = arrOfObj => arrOfObj.map(row => Object.values(row))

        const userRowsArray = convertToArrOfArrays(userRows).map(row => row.filter(item => typeof item !== 'object'))

        const summaryByCleaningTypeArray =
            summaryByCleaningType && summaryByCleaningType.length > 0 && convertToArrOfArrays(summaryByCleaningType)

        return summaryByCleaningTypeArray
            ? [headers, ...userRowsArray, [], ['Summary by cleaning type'], cleaningSummaryHeaders, ...summaryByCleaningTypeArray]
            : [headers, ...userRowsArray]
    }
})
