import * as constants from './txt-constants'
import * as aghelpers from './area-groups-helpers'
import * as dataObjects from './dataObjects'
import { ActivityStruct, getMiniUserObject } from './dataObjects'
import moment from 'moment-timezone'
import { ActivityChangeObject, ActivityType, AreaStruct, DailyCommentStruct, RuleStruct, UserStruct } from './firestore-structs'
import { Firebase, FirebaseFirestore, WriteBatch } from './firebase'
import { getOrganization } from './org-data'
import { AreaCleaningStatus } from './firestore-structs'
import { removeUndefinedValues } from './helpers'

export function getAreasQuery(
    firebase: Firebase | FirebaseFirestore,
    organizationKey: string,
    includeCleaningStatus = true,
    areaGroups?: string[]
) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    let query = db.collection<AreaStruct>('areas').where('organizationKey', '==', organizationKey).where('visible', '==', true)

    if (includeCleaningStatus) {
        query = query.where('displayCleaningStatus', '==', true)
    }

    if (areaGroups && areaGroups.length > 0 && areaGroups[0] !== constants.AREA_GROUPS_ALL) {
        query = query.where('group', 'in', areaGroups)
    }

    return query
}

export async function getHousekeepingDashboardAreas(firebase: Firebase, organizationKey: string) {
    const areasQuery = getAreasQuery(firebase, organizationKey).where('displayCleaningStatus', '==', true)

    const areasRef = await areasQuery.get()

    const areas = !areasRef.empty ? areasRef.docs.map(b => b.data()) : []

    return areas
}

export async function getArea(firebase: Firebase, areaKey: string) {
    const doc = await firebase.firestore().collection('areas').doc(areaKey).get()
    return doc.data() as AreaStruct
}

export function getAreaQuery(firebase: Firebase, areaKey: string) {
    return firebase.firestore().collection('areas').doc(areaKey)
}

export async function fetchAreas(firebase: Firebase, currentUser: UserStruct, filterAreaGroups = true) {
    try {
        const areasSnap = await firebase
            .firestore()
            .collection('areas')
            .where('organizationKey', '==', currentUser.organizationKey)
            .where('visible', '==', true)
            .get()
        let allAreas: Partial<AreaStruct>[] = []
        if (areasSnap) {
            areasSnap.forEach(areaSnap => {
                const area = areaSnap.data() as AreaStruct & { fullDescription?: string }
                area.fullDescription = area.name + ' ' + area.description
                allAreas.push(area)
            })

            if (filterAreaGroups) {
                allAreas = aghelpers.filterByAreaGroupAccess(currentUser, allAreas)
            }
        }

        return allAreas
    } catch (error) {
        console.log('(AreaData fetchAreas) ', error)
        if (firebase.crashlytics) {
            firebase.crashlytics().setUserId(currentUser.phoneNumber)
            firebase.crashlytics().setAttribute('user', JSON.stringify(currentUser))
            firebase.crashlytics().setAttribute('path', '(AreaData fetchAreas)')
            firebase.crashlytics().recordError(error)
        }
        return []
    }
}

export async function fetchAreaGroups(firebase: Firebase, currentUser: UserStruct) {
    try {
        const areas = (await fetchAreas(firebase, currentUser, false)) as AreaStruct[]
        const groups = [...new Set(areas.map(area => area.group))] as string[]
        groups.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase(), 'en', { sensitivity: 'base' }))

        return groups
    } catch (error) {
        console.log('(AreaData fetchAreaGroups) ', error)
        if (firebase.crashlytics) {
            firebase.crashlytics().setUserId(currentUser.phoneNumber)
            firebase.crashlytics().setAttribute('user', JSON.stringify(currentUser))
            firebase.crashlytics().setAttribute('path', '(AreaData fetchAreaGroups)')
            firebase.crashlytics().recordError(error)
        }
        throw error
    }
}

export async function createArea(firebase: Firebase, area: Partial<AreaStruct & { rules?: RuleStruct[] }>, currentUser: UserStruct) {
    const areaRef = firebase.firestore().collection('areas').doc()
    const org = await getOrganization(firebase, currentUser.organizationKey)
    if (org) {
        moment.tz.setDefault(org.timezone)
    }

    const areaObject = {
        key: areaRef.id,
        creator: currentUser.key,
        name: area.name,
        description: area.description,
        address: area.address,
        organizationKey: currentUser.organizationKey,
        group: area.group,
        updated: moment().valueOf(),
        lastChangedByUser: currentUser.key,
        rules: area.rules || [], // TODO: this should be removed as there is no "rules" in AreaStruct
        displayCleaningStatus: area.displayCleaningStatus,
        visible: true
    } as Partial<AreaStruct>

    if (area.displayCleaningStatus) {
        areaObject.cleaningStatus = constants.CLEANING_STATUS_CLEAN
    }

    await areaRef.set(areaObject, {}).catch(error => {
        console.log('(areaData createArea) ', error)
        if (firebase.crashlytics) {
            firebase.crashlytics().setUserId(currentUser.phoneNumber)
            firebase.crashlytics().setAttribute('area', JSON.stringify(areaObject))
            firebase.crashlytics().setAttribute('path', '(AreaData createArea)')
            firebase.crashlytics().recordError(error)
        }
    })
    return areaRef.id
}

export async function updateArea(
    firebase: Firebase | FirebaseFirestore,
    areaKey: string,
    area: Partial<AreaStruct>,
    currentUser: UserStruct
) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    const areaRef = db.collection('areas').doc(areaKey)
    const org = await getOrganization(firebase, currentUser.organizationKey)
    if (org) {
        moment.tz.setDefault(org.timezone)
    }

    area.updated = moment().valueOf()
    area.lastChangedByUser = currentUser.key

    if (area.displayCleaningStatus && !area.cleaningStatus) {
        area.cleaningStatus = constants.CLEANING_STATUS_CLEAN
    }

    await areaRef.update(area).catch(error => {
        console.log('(areaData updateArea) ', error)
    })
}

export async function deleteArea(firebase: Firebase, areaKey: string, currentUser: UserStruct) {
    const areaRef = firebase.firestore().collection('areas').doc(areaKey)
    const org = await getOrganization(firebase, currentUser.organizationKey)
    if (org) {
        moment.tz.setDefault(org.timezone)
    }

    const areaObject = {
        lastChangedByUser: currentUser.key,
        updated: moment().valueOf(),
        visible: false
    }

    await areaRef.update(areaObject).catch(error => {
        console.log('(areaData deleteArea) ', error)
        if (firebase.crashlytics) {
            firebase.crashlytics().setUserId(currentUser.phoneNumber)
            firebase.crashlytics().setAttribute('areaKey', JSON.stringify(areaKey))
            firebase.crashlytics().setAttribute('path', '(Area deleteArea)')
            firebase.crashlytics().recordError(error)
        }
    })
}

export async function updateAreaCleaningStatus(
    firebase: Firebase | FirebaseFirestore,
    {
        areaKey,
        currentUser,
        taskKey,
        cleaningStatus,
        cleaningPriority
    }: { areaKey: string; currentUser: UserStruct; taskKey?: string; cleaningStatus: AreaCleaningStatus; cleaningPriority?: boolean },
    externalBatch?: WriteBatch
) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    const areaRef = db.collection('areas').doc(areaKey)
    const areaUpdate = removeUndefinedValues({
        updated: moment().valueOf(),
        cleaningStatus: cleaningStatus,
        lastCleaningTaskKey: taskKey,
        cleaningPriority: cleaningPriority ? cleaningPriority : false,
        lastStatusChange: {
            user: getMiniUserObject(currentUser),
            updated: moment().valueOf()
        }
    })

    externalBatch ? externalBatch.update(areaRef, areaUpdate) : await areaRef.update(areaUpdate)
}

export async function logActivity(
    firebase: Firebase | FirebaseFirestore,
    currentUser: UserStruct,
    areaKey: string,
    type: ActivityType,
    selectedDateNumber: number,
    changeObj: ActivityChangeObject,
    externalBatch?: WriteBatch,
    activityKey?: string,
    createdTimestamp?: number
) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    const activityRef = db.collection('areas').doc(areaKey).collection('activities')

    const refId = activityKey ?? activityRef.doc().id
    const activity = {
        areaKey: areaKey,
        type: type,
        date: selectedDateNumber,
        created: createdTimestamp ?? moment().valueOf(),
        change: changeObj,
        key: refId,
        organizationKey: currentUser.organizationKey,
        user: dataObjects.getMiniUserObject(currentUser)
    }
    if (externalBatch) {
        externalBatch.set(activityRef.doc(refId), activity)
    } else {
        await activityRef.doc(refId).set(activity)
    }
}

export async function getActivities(firebase: Firebase, areaKey: string, selectedDateNumber: number) {
    const activityRef = await firebase
        .firestore()
        .collection('areas')
        .doc(areaKey)
        .collection<ActivityStruct>('activities')
        .where('date', '==', selectedDateNumber)
        .get()

    let activities = !activityRef.empty ? activityRef.docs.map(a => a.data()) : []
    activities = activities.sort((a, b) => b.created - a.created)

    return activities
}

export async function touchArea(firebase: Firebase | FirebaseFirestore, areaKey: string, currentUser: UserStruct) {
    const org = await getOrganization(firebase, currentUser.organizationKey)
    if (org) {
        moment.tz.setDefault(org.timezone)
    }

    const areaObject = {
        lastStatusChange: {
            user: dataObjects.getMiniUserObject(currentUser),
            updated: moment().valueOf()
        }
    }
    await updateArea(firebase, areaKey, areaObject, currentUser)
}

function createDailyCommentObject(
    dailyCommentKey: string,
    areaKey: string,
    date: number,
    comment: string,
    creator: UserStruct
): DailyCommentStruct {
    const timeStamp = moment().valueOf()
    return {
        key: dailyCommentKey,
        organizationKey: creator.organizationKey,
        areaKey: areaKey,
        created: timeStamp,
        date: date,
        comment: comment,
        creator: {
            key: creator.key,
            initials: creator.initials,
            name: creator.name
        },
        visible: true
    }
}

export async function updateDailyComment(
    firebase: Firebase,
    areaKey: string,
    dailyComment: DailyCommentStruct,
    newComment: string,
    currentUser: UserStruct
): Promise<DailyCommentStruct> {
    const db = firebase.firestore()
    const batch = db.batch()
    const org = await getOrganization(firebase, currentUser.organizationKey)
    if (org) {
        moment.tz.setDefault(org.timezone)
    }

    const areaRef = db.collection('areas').doc(areaKey)
    const dailyCommentRef = areaRef.collection('dailyComments').doc(dailyComment.key)
    const timeStamp = moment().valueOf()
    const updateObject = {
        comment: newComment,
        creator: {
            key: currentUser.key,
            initials: currentUser.initials,
            name: currentUser.name
        },
        updated: timeStamp
    }
    const dailyCommentState = JSON.parse(JSON.stringify(dailyComment))
    dailyCommentState.comment = updateObject.comment
    dailyCommentState.creator = updateObject.creator
    dailyCommentState.updated = updateObject.updated

    batch.update(dailyCommentRef, updateObject)
    await batch.commit().catch(error => {
        console.log('(updateDailyComment) ', error)
    })

    return dailyCommentState
}

export async function createDailyComment(
    firebase: FirebaseFirestore | Firebase,
    areaKey: string,
    date: number,
    comment: string,
    currentUser: UserStruct
) {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    const batch = db.batch()
    const org = await getOrganization(firebase, currentUser.organizationKey)
    if (org) {
        moment.tz.setDefault(org.timezone)
    }

    const areaRef = db.collection('areas').doc(areaKey)
    const dailyCommentRef = areaRef.collection('dailyComments').doc()

    const dailyCommentObject = createDailyCommentObject(dailyCommentRef.id, areaKey, date, comment, currentUser)

    batch.set(dailyCommentRef, dailyCommentObject)

    await batch.commit().catch(error => {
        console.log('(createDailyComment) ', error)
    })

    return dailyCommentObject
}

export async function getAreas(firebase: Firebase, organizationKey: string) {
    const areasRef = await getAreasQuery(firebase, organizationKey, false).get()

    return areasRef.docs.map(areaSnap => {
        return areaSnap.data() as AreaStruct
    })
}

const updateOrgTotalSyncedAreas = async (
    params: { orgId: string; areasSyncedTotal: number; areasNotSyncedTotal: number },
    db: FirebaseFirestore
) => {
    await db
        .collection('organizations')
        .doc(params.orgId)
        .update({ areasSyncedTotal: params.areasSyncedTotal, areasNotSyncedTotal: params.areasNotSyncedTotal })
}

export async function updateOrgAreasStatistics(db: FirebaseFirestore, orgKey: string) {
    const visibleAreas = await db.collection<AreaStruct>('areas').where('organizationKey', '==', orgKey).where('visible', '==', true).get()
    const { synced, notSynced } = visibleAreas.docs
        .map(area => (area.data().synced || false) && (area.data().displayCleaningStatus ?? true))
        .reduce(
            (acc, syncedValue) => ({
                synced: acc.synced + (syncedValue ? 1 : 0),
                notSynced: acc.notSynced + (syncedValue ? 0 : 1)
            }),
            {
                synced: 0,
                notSynced: 0
            }
        )

    await updateOrgTotalSyncedAreas(
        {
            orgId: orgKey,
            areasSyncedTotal: synced,
            areasNotSyncedTotal: notSynced
        },
        db
    )
}
